import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import Chaining from '../classes/Chaining'
import {ChainingStoreAdministratedProductDto} from '../store/types/ChainingStoreAdministratedProduct/ChainingStoreAdministratedProductDto'
import AdministratedProduct from '../classes/AdministratedProduct'
import {ChainingDefaultApiAdapter} from './ChainingDefaultApiAdapter'


export class ChainingAdministratedProductApiAdapter implements ApiDataAdapter<AdministratedProduct> {
    public createClass(apiData: ChainingStoreAdministratedProductDto): AdministratedProduct {
        const associatedPatientServiceApi = get(apiData, 'chainingAssociatedPatientService')

        let chainings: Chaining[] = []

        if (associatedPatientServiceApi) {
            if (Array.isArray(associatedPatientServiceApi)) {
                chainings = associatedPatientServiceApi.map( chaining => new ChainingDefaultApiAdapter().createClass(chaining))
            } else {
                chainings.push(new ChainingDefaultApiAdapter().createClass(associatedPatientServiceApi))
            }
        }

        const returnedAdministratedProduct = new AdministratedProduct(
            get(apiData, 'id'),
            get(apiData, '_type'),
            get(apiData, 'administrateur_id'),
            get(apiData, 'quantite'),
            get(apiData, '_unite_prise_label'),
            get(apiData, '_chaining_object_label'),
            get(apiData, '_chaining_object_type'),
            chainings,
            get(apiData, 'creation_date'),
            get(apiData, '_chaining_object_datetime'),
        )

        chainings.forEach( chaining => {
            chaining.objectClass = apiData._type
            chaining.objectId = apiData.id
            chaining.administratedProduct = returnedAdministratedProduct
        })

        return returnedAdministratedProduct
    }
}
