var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "chaining-service-found-selector" },
    [
      _c(
        "kh-modal",
        {
          attrs: {
            "max-width": "1000px",
            scrollable: "",
            transition: "dialog-bottom-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("chaining:chaining-service_code_scan")) +
                      "\n        "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.chainingDuplicateServiceConfirmTrad),
            },
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("chaining:chaining-contact_admin_for_param"))),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("chaining:chaining-choose_service_to_add"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "kh-list-header" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("chaining:chaining-service-search-result")) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("kh-extended-list", {
            key: _vm.key,
            attrs: {
              config: _vm.foundServiceListConfig,
              selectable: "",
              returnFlat: "",
            },
            on: { onSelectionChange: _vm.updateSelectedServices },
            scopedSlots: _vm._u([
              {
                key: "chainingService",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      {
                        staticClass: "align-center",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "mr-5",
                            attrs: { cols: "auto", "align-self": "center" },
                          },
                          [
                            _c("kh-icon", {
                              staticClass: "selectedService",
                              attrs: { icon: "prestationAdd" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "service-cart-ellipsis" },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "mx-0 selectedService" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-subtitle",
                              { staticClass: "mx-0 selectedService" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.code) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "kh-button",
                    {
                      attrs: {
                        disabled: _vm.selectedService === null,
                        block: "",
                        color: "primary",
                        depressed: "",
                      },
                      on: { click: _vm.emitSelection },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("clevehr:Add")) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }