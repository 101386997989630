import { get } from 'lodash'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { OneOrMore, Undefinable } from '@internal-libraries/kheops-ui-lib'
import Chaining from '../classes/Chaining'
import ChainingService from '../classes/ChainingService'
import ChainingCatalog from '../classes/ChainingCatalog'
import { ChainingServiceApiAdapter } from './ChainingServiceApiAdapter'
import { ChainingCatalogApiAdapter } from './ChainingCatalogApiAdapter'
import { ChainingElementDto } from '../types/ChainingElement/ChainingElementDto'
import { ChainingCatalogDto } from '../types/ChainingCatalogDto'
import { ChainingServiceDto } from '../types/ChainingServiceDto'
import { ChainingCategoryDto } from '../types/ChainingCategoryDto'
import { ChainingCategoryApiAdapter } from './ChainingCategoryApiAdapter'

export class ChainingApiAdapter implements ApiDataAdapter<Chaining> {
    public createClass(apiData: ChainingElementDto): Chaining {
        const catalogsDto: Undefinable<OneOrMore<ChainingCatalogDto>> = get(apiData, 'catalogs')
        let catalogs: ChainingCatalog[] = []
        const servicesDto: Undefinable<OneOrMore<ChainingServiceDto>> = get(apiData, 'services')
        let services: ChainingService[] = []

        if (catalogsDto) {
            if (Array.isArray(catalogsDto)) {
                catalogs = catalogsDto.map(catalog => new ChainingCatalogApiAdapter().createClass(catalog))
            } else {
                catalogs = [new ChainingCatalogApiAdapter().createClass(catalogsDto)]
            }
        }

        const categoryDto: Undefinable<ChainingCategoryDto> = get(apiData, '_ref_chaining_category')

        const category = categoryDto ? new ChainingCategoryApiAdapter().createClass(categoryDto) : null

        if (servicesDto) {
            if (Array.isArray(servicesDto)) {
                services = servicesDto.map(service => {
                    return new ChainingServiceApiAdapter().createClass(service)
                })
            } else {
                services = [new ChainingServiceApiAdapter().createClass(servicesDto)]
            }
        }

        return new Chaining(
            get(apiData, 'id', -1),
            get(apiData, 'name', ''),
            get(apiData, 'specialty_id', -1),
            get(apiData, 'category_id', -1),
            get(apiData, 'is_active', true),
            services,
            catalogs,
            category,
        )
    }
}
