// Comes from CUser::$types
export enum UserGenericTypes {
    ADMIN = 1,
    HOSTESS,
    SURGEON,
    ANAESTHETIST,
    DIRECTOR,
    ACCOUNTANT,
    NURSE,
    PMSI,
    QUALITY,
    SECRETARY,
    OPERATION_ROOM_SUPERVISOR = 12,
    DOCTOR,
    STAFF,
    REEDUCATOR,
    MIDWIFE,
    PHARMACIST,
    NURSING_ASSISTANT,
    DENTIST,
    PREPARATOR,
    DIETETICIAN,
    PATIENT,
    ASSC,
    IADE,
    SOCIAL_ASSISTANT,
}
