import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import ChainingCategory from '../classes/ChainingCategory'
import { ChainingCategoryDto } from '../types/ChainingCategoryDto'


export class ChainingCategoryApiAdapter implements ApiDataAdapter<ChainingCategory> {
    createClass(apiData: ChainingCategoryDto): ChainingCategory {
        return new ChainingCategory(
            get(apiData, 'date_created', ''),
            get(apiData, 'date_modified', ''),
            get(apiData, 'id', -1),
            get(apiData, 'specialty_id', -1),
            get(apiData, 'name', ''),
        )
    }
}
