import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import {get} from "lodash"
import { ChainingCareCatalogTypeRef } from "./ChainingCareCatalogTypeRef"
import { ChainingCareCatalogTypeDto } from "../types/ChainingCareCatalogTypeDto"

export class ChainingCareCatalogTypeApiAdapter implements ApiDataAdapter<ChainingCareCatalogTypeRef> {
    createClass(apiData: ChainingCareCatalogTypeDto): ChainingCareCatalogTypeRef {
        return new ChainingCareCatalogTypeRef(
            get(apiData, 'id'),
            get(apiData, 'name')
        )
    }
}
