import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import { User } from '../classes/User'
import { UserDto } from '../types/UserDto'

export class UserAPIAdapter implements ApiDataAdapter<User> {
    public createClass(apiData: UserDto): User {
        return new User({
            id: Number(get(apiData, 'id')),
            userUsername: get(apiData, 'user_username'),
            userPassword: get(apiData, 'user_password'),
            userFirstName: get(apiData, 'user_first_name'),
            userType: get(apiData, 'user_type'),
            userLastName: get(apiData, 'user_last_name'),
            userSex: get(apiData, 'user_sexe'),
            shortview: get(apiData, '_shortview'),
            view: get(apiData, '_view'),
        })
    }
}
