import { ChainingCareCatalog } from './ChainingCareCatalog'
import { ChainingDrugCatalog } from '../classes/ChainingDrugCatalog'
import { ChainingOperationCatalog } from '../classes/ChainingOperationCatalog'
import { Nullable } from '@internal-libraries/kheops-ui-lib'

export default class ChainingCatalog {
    public chainingCareCatalog?: ChainingCareCatalog
    public chainingDrugCatalog?: ChainingDrugCatalog
    public chainingOperationCatalog?: ChainingOperationCatalog

    constructor(
        public id: Nullable<number>,
        public objectClass: string,
        public objectId: number,
        public chainingId: number,
        public typeId: number,
        public isActive: boolean,
        public creationDate: Date,
        chainingObjectCatalog?: ChainingCareCatalog | ChainingDrugCatalog | ChainingOperationCatalog,
    ) {
        switch (objectClass) {
            case 'CElementPrescription':
                this.chainingCareCatalog = chainingObjectCatalog as ChainingCareCatalog
                break
            case 'CCompendiumProduct':
                this.chainingDrugCatalog = chainingObjectCatalog as ChainingDrugCatalog
                break
            case 'CProtocole':
                this.chainingOperationCatalog = chainingObjectCatalog as ChainingOperationCatalog
                break
        }
    }
}
