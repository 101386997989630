import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import {get} from 'lodash'
import { ChainingOperationCatalog } from '../classes/ChainingOperationCatalog'
import { ChainingOperationCatalogDto } from '../types/ChainingOperationCatalogDto'

export class ChainingOperationCatalogApiAdapter implements ApiDataAdapter<ChainingOperationCatalog> {
    createClass(operationCatalog: ChainingOperationCatalogDto): ChainingOperationCatalog {
        return new ChainingOperationCatalog(
            get(operationCatalog, 'id', -1),
            get(operationCatalog, 'libelle', '')
        )
    }
}
