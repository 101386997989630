import { get } from 'lodash'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { ChainingUserDto } from '../types/ChainingUser/ChainingUserDto'
import ChainingUser from '../classes/ChainingUser'

export class ChainingUserApiAdapter implements ApiDataAdapter<ChainingUser> {
    public createClass(apiData: ChainingUserDto): ChainingUser {
        return new ChainingUser(
            get(apiData, 'id'),
            get(apiData, 'user_first_name'),
            get(apiData, 'user_last_name'),
            get(apiData, 'user_username')
        )
    }
}
