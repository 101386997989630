import { UserType } from '../types/UserType'
import { UserGenericTypes } from '../enums/UserGenericTypes'

export class User {
    public readonly practitionerTypeList = [
        UserGenericTypes.DOCTOR,
        UserGenericTypes.SURGEON,
        UserGenericTypes.ANAESTHETIST,
        UserGenericTypes.DENTIST,
    ]

    public id!: number
    public userUsername!: string
    public userPassword!: string
    public userFirstName!: string
    public userType!: UserGenericTypes

    public userLastName?: string
    public userSex?: string
    public shortview?: string
    public view?: string

    public constructor(data: UserType) {
        Object.assign(this, data)
    }

    public get isNurse(): boolean {
        return this.userType === UserGenericTypes.NURSE
    }

    public get isPractitioner(): boolean {
        return this.practitionerTypeList.includes(this.userType)
    }

    public get isPharmacist(): boolean {
        return this.userType === UserGenericTypes.PHARMACIST
    }

    public get label(): string {
        return this.view ?? `${this.userFirstName} ${this.userLastName}`
    }
}
