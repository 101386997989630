export default class ChainingUser {
    public constructor(
        public id,
        public firstName,
        public lastName,
        public userName
    ) {}

    public getFullName(){
        return (this.lastName || '') + ' ' + (this.firstName || '')
    }

    public getInitials(){
        let firstChar = ''
        let secondChar = ''
        if(this.firstName){
            firstChar = this.firstName[0].toUpperCase()
        }
        if(this.lastName){
            secondChar = this.lastName[0].toUpperCase()
        }

        return firstChar + secondChar
    }
}
