import { Nullable } from '@internal-libraries/kheops-ui-lib'
import ChainingService from './ChainingService'
import ChainingCatalog from './ChainingCatalog'
import * as ChainingDefaultConstants from '../types/ChainingDefault/ChainingDefaultConstants'
import { ChainingIconAndName } from '../types/ChainingIconAndName'
import AdministratedProduct from '../classes/AdministratedProduct'
import _ from 'lodash'
import { CheckboxState } from '@internal-libraries/kheops-ui-lib'
import ChainingCategory from './ChainingCategory'

export default class Chaining {
    public selected = false
    public selectedState = CheckboxState.unchecked
    public selectedServices: ChainingService[] = []
    public nonSelectableServices: ChainingService[] = []
    public specialtyName = ''
    public categoryName = ''
    public associatedPatientServiceId: Nullable<number> = null
    public contextsNames: ChainingIconAndName[] = []
    // objectClass and objectId refers to which object this chaining is attached for
    // useful in health care followup
    public objectClass: Nullable<string> = null
    public objectId: Nullable<number> = null
    public administratedProduct: Nullable<AdministratedProduct> = null

    public constructor(
        public id: Nullable<number>,
        public name: string,
        public specialtyId: number = ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID,
        public categoryId: number = ChainingDefaultConstants.CHAINING_NO_INSERTED_ID,
        public isActive: boolean = true,
        public services: ChainingService[] = [],
        public catalogs: ChainingCatalog[] = [],
        public selectedCategory: Nullable<ChainingCategory> = null,
    ) {}

    /**
     * Filter by name
     * Case-insensitive and ignore accents
     */
    public static filterByName(list: Chaining[], search: string): Chaining[] {
        const keywords = search.replace(/\s+/g, ' ').split(' ')
        // filtering chaining
        return list.filter((chaining: Chaining) => {
            const name = _.camelCase(chaining.name)
            for (const keyword of keywords) {
                if (-1 === name.search(new RegExp(_.escapeRegExp(_.camelCase(keyword)), 'i'))) {
                    return false
                }
            }
            return true
        })
    }

    public resetSelection(): void {
        this.selectedState = CheckboxState.unchecked
        this.selected = false
        this.selectedServices = []
    }

    public setSelected(): void {
        this.selected = true
        this.selectedState = CheckboxState.checked
    }
}
