export enum ChainingPermissions {
    ADD_SERVICE = 'chaining.edit',
    DELETE_SERVICE = 'chaining.edit',
    ADD_SERVICE_OUTSIDE_CHAINING = 'chaining.edit',
    ADD_CHAINING = 'chaining.edit',
    DELETE_CHAINING = 'chaining.edit',
    EDIT_SERVICE_PROVIDER_CODE = 'chaining.edit',
    EDIT_SERVICE_QUANTITY = 'chaining.edit',
    VALIDATE_SERVICE = 'chaining.validateService',
    INVALIDATE_SERVICE = 'chaining.validateService'
}
