import { OneOrMore } from '@internal-libraries/kheops-ui-lib'
import Vue from 'vue'
import { PermissionsApiAdapter } from './PermissionsApiAdapter'
import PermissionsDto from './PermissionsDto'
import { JsonApiResponseAdapter } from '@internal-libraries/kheops-ui-lib'

/**
 * @deprecated please use PermissionsStore
 */
export class PermissionsService {
    private permissionList: Record<string, boolean> = {}

    private getAcl(keys: OneOrMore<string>): boolean {
        if (Array.isArray(keys)) {
            for (const key of keys) {
                if (!this.permissionList[key]) return false
            }
            return true
        }
        return this.permissionList[keys] || false
    }

    public isAllowed(keys: OneOrMore<string>): boolean {
        return this.getAcl(keys)
    }

    public isDenied(keys: OneOrMore<string>): boolean {
        return !this.getAcl(keys)
    }

    public load(module: string): Promise<JsonApiResponseAdapter<PermissionsDto>> {
        const apiCall = Vue.$http.get('api/v1/admin/acl/' + module, new PermissionsApiAdapter())
        apiCall.subscribe((response: JsonApiResponseAdapter<PermissionsDto>) => {
            const data = response.getOneData()
            if (data) {
                for (const [key, value] of Object.entries(data)) {
                    // we delete basic keys from api return
                    if ('id' === key || '_type' === key) continue
                    this.set(key, value)
                }
            }
        })
        return apiCall.toPromise()
    }

    public set(key: string, value: boolean): void {
        this.permissionList[key] = value
    }
}
