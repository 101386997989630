import ChainingStoreGetters from './ChainingStoreGetters'
import ChainingStoreActions from './ChainingStoreActions'
import ChainingStoreMutations from './ChainingStoreMutations'
import ChainingStoreState from './ChainingStoreState'

export default {
    chainingStore: {
        namespaced: false,
        state: ChainingStoreState,
        getters: ChainingStoreGetters,
        mutations: ChainingStoreMutations,
        actions: ChainingStoreActions
    }
}
