export default class ChainingSpecialty {

    public date_created: Date
    public date_modified: Date
    public isReferenced = false

    constructor(
        date_created: string,
        date_modified: string,
        public id: number = -1,
        public name: string = ''
    ) {
        this.date_created = date_created ? new Date(date_created) : new Date()
        this.date_modified =  date_modified ? new Date(date_modified) : new Date()
    }
}
