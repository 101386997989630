import Vue from 'vue'
import { tap } from 'rxjs/operators'
import { ChainingTypeApiAdapter } from '../adapter/ChainingTypeApiAdapter'
import { ChainingSpecialtyApiAdapter } from '../adapter/ChainingSpecialtyApiAdapter'
import { ChainingCategoryApiAdapter } from '../adapter/ChainingCategoryApiAdapter'
import { ChainingDefaultApiAdapter } from '../adapter/ChainingDefaultApiAdapter'
import { ChainingFromServiceApiAdapter } from '../adapter/ChainingFromServiceApiAdapter'
import { ChainingCashBenefitToServiceApiAdapter } from '../adapter/ChainingCashBenefitApiAdapter'
import { ChainingServiceApiAdapter } from '../adapter/ChainingServiceApiAdapter'
import { ChainingApiAdapter } from '../adapter/ChainingApiAdapter'
import { ChainingCareCatalogTypeRef } from '../adapter/ChainingCareCatalogTypeRef'
import { ChainingCareCatalogTypeApiAdapter } from '../adapter/ChainingCareCatalogTypeApiAdapter'
import { ChainingUserPreferenceApiAdapter } from '../adapter/ChainingUserPreferenceApiAdapter'
import { ChainingAdministratedProductApiAdapter } from '../adapter/ChainingAdministratedProductApiAdapter'
import { ChainingProviderApiAdapter } from '../adapter/ChainingProviderApiAdapter'

import ChainingType from '../classes/ChainingType'
import ChainingSpecialty from '../classes/ChainingSpecialty'
import ChainingCategory from '../classes/ChainingCategory'
import Chaining from '../classes/Chaining'
import ChainingService from '../classes/ChainingService'
import ChainingHelper from '../classes/ChainingHelper'
import AdministratedProduct from '../classes/AdministratedProduct'
import ChainingProvider from '../classes/ChainingProvider'

import { ChainingStoreLoad } from '../types/ChainingStoreLoad'
import { ChainingStoreServices } from '../types/ChainingStoreServices'
import { ChainingStoreInit } from '../types/ChainingStoreInit'
import { ChainingStoreDefaultChainingLoad } from '../types/ChainingStoreDefaultChainingLoad'
import { ChainingManagementCatalogService } from '../types/ChainingManagementCatalogService'
import { ChainingStoreDrugInit } from '../types/ChainingStoreDrugInit'
import { ChainingAssociatedPatientServiceUpdateDto } from '../types/ChainingAssociatedPatientService'
import { ChainingPatientServiceDto, ChainingPatientServiceUpdateDto } from '../types/ChainingPatientService'
import * as ChainingDefaultConstants from '../types/ChainingDefault/ChainingDefaultConstants'
import { ChainingStoreAddAssociatedPatientService, ChainingStoreAddPatientService } from './types/'
import { ChainingsFromService } from '../types/ChainingsFromService'
import { ChainingReplaceService } from '../types/ChainingReplaceService'
import { ChainingUserPreferenceCreateDto } from '../types/ChainingUserPreference/ChainingUserPreferenceCreateDto'
import { ChainingApiParams } from '../types/api/ChainingApiParams'
import { ChainingUserPreferenceUpdateDto } from '../types/ChainingUserPreference/ChainingUserPreferenceUpdateDto'
import { ChainingStoreAdministratedProductLoad } from './types/ChainingStoreAdministratedProduct/ChainingStoreAdministratedProductLoad'
import { ChainingSpecialtyDto } from '../types/ChainingSpecialtyDto'
import { ChainingCatalogContextEnum } from '../types/ChainingCatalogContext'
import { ChainingIsReferenced } from '../types/api/ChainingIsReferenced'
import { ChainingStoreManagementCatalog } from '../types/ChainingStoreManagementCatalog'
import { JsonApiResponseAdapter, RawApiDataAdapter } from '@internal-libraries/kheops-ui-lib'

export default {
    loadChainingProviders(data, args): Promise<ChainingProvider[]> {
        const params =
            args.groupId !== ChainingDefaultConstants.CHAINING_NO_INSERTED_ID
                ? {
                      groupId: args.groupId,
                  }
                : {}
        const apiCall = Vue.prototype.$http.get(
            'api/v1/chaining/patientService/provider',
            new ChainingProviderApiAdapter(),
            params,
        )
        apiCall.subscribe(response => {
            const result = response.getManyData()
            data.commit('saveChainingProviders', {
                chainingProviders: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadChainingCareContext(data, args): Promise<ChainingCareCatalogTypeRef[]> {
        const params =
            args.groupId !== ChainingDefaultConstants.CHAINING_NO_INSERTED_ID
                ? {
                      groupId: args.groupId,
                  }
                : {}
        const apiCall = Vue.prototype.$http.get(
            'api/v1/prescription/careCatalog',
            new ChainingCareCatalogTypeApiAdapter(),
            params,
        )
        apiCall.subscribe(response => {
            const result = response.getManyData()
            data.commit('saveChainingCareCatalogs', {
                careCatalogs: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadChainingTypes(data): Promise<ChainingType[]> {
        const apiCall = Vue.prototype.$http.get('api/v1/chaining/type', new ChainingTypeApiAdapter())
        apiCall.subscribe(response => {
            const result = response.getManyData()
            data.commit('saveChainingTypes', result)
        })
        return apiCall.toPromise()
    },
    loadChainingSpecialties(data): Promise<ChainingSpecialty[]> {
        const apiCall = Vue.prototype.$http.get('api/v1/chaining/specialty', new ChainingSpecialtyApiAdapter())
        apiCall.subscribe(response => {
            const result = response.getManyData()
            data.commit('saveChainingSpecialties', result)
            data.commit('sortSpecialties')
        })
        return apiCall.toPromise()
    },
    /**
     * Used to load all categories from database
     * if args.id (specialty id) is specified, categories all filtered by this argument
     * @param data
     * @param args
     */
    loadChainingCategories(data, args): Promise<ChainingCategory[]> {
        const apiCall = Vue.prototype.$http.get(
            'api/v1/chaining/category' + (args.id ? '?specialty_id=' + args.id : ''),
            new ChainingCategoryApiAdapter(),
        )
        apiCall.subscribe(response => {
            let result = response.getManyData()
            // this check is useful if id is filled
            if (!Array.isArray(result)) result = result ? [result] : []
            data.commit('saveChainingCategories', {
                categories: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    /**
     * Used to load all services from database
     * @param data
     * @param args
     */
    loadChainingServices(data, args: ChainingStoreServices): Promise<JsonApiResponseAdapter<ChainingService>> {
        const searchDialogList = args.searchDialogList
        const defaultQuantity = args.defaultQuantity ? args.defaultQuantity : 1

        const params: any = {
            search: searchDialogList,
            relations: 'caisseMaladie',
        }
        // add catalog ID if exists
        const apiCall = Vue.$http.get(
            'api/v1/tarmed/prestationCaisse',
            new ChainingCashBenefitToServiceApiAdapter(),
            params,
        )
        apiCall.subscribe(response => {
            const result = response.getManyData()
            result?.forEach(service => {
                service.quantity = defaultQuantity
            })
            data.commit('saveChainingServices', {
                services: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadDefaultChainings: function (data, args: ChainingStoreDefaultChainingLoad): Promise<Chaining[]> {
        const apiCall = Vue.prototype.$http.get(
            'api/v1/chaining/associatedPatientService/' +
                args.apiRoad +
                '/' +
                args.objectClass +
                '/' +
                args.objectId +
                '?populate=patientService,chaining,patientServiceUser,patientServiceStatusUser' +
                (args.specialtyId && args.specialtyId !== ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID
                    ? '&specialtyId=' + (args.showAllSpecialty ? 'null,' : '') + args.specialtyId
                    : ''),
            new ChainingDefaultApiAdapter(),
        )
        apiCall.subscribe(response => {
            const result = response.getManyData()
            const toStore = result
                .filter(chaining => chaining.isActive) // @TODO API should not return chainings with is_active false
                .sort((a, b) => {
                    // we put virtual chaining at the end of the list
                    if (a.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) return 1
                    if (b.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) return -1
                    return ChainingHelper.compareTwoStrings(a.name.toLowerCase(), b.name.toLowerCase())
                })
            data.commit('saveChainingDefault', {
                data: toStore,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadChainingsStayContext: function (data, args: ChainingStoreDefaultChainingLoad): Promise<Chaining[]> {
        const apiCall = Vue.prototype.$http.get(
            'api/v1/chaining/associatedPatientService/initFromObject/' +
                args.objectClass +
                '/' +
                args.objectId +
                '?populate=patientService,chaining',
            new ChainingDefaultApiAdapter(),
        )
        apiCall.subscribe(response => {
            const result = response.getManyData()
            const toStore = result
                .filter(chaining => chaining.isActive) // @TODO API should not return chainings with is_active false
                .sort((a, b) => {
                    // we put virtual chaining at the end of the list
                    if (a.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) return 1
                    if (b.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) return -1
                    return ChainingHelper.compareTwoStrings(a.name.toLowerCase(), b.name.toLowerCase())
                })
            data.commit('saveChainingStayContext', {
                data: toStore,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadCareFollowUpAdministratedProducts: function (
        data,
        args: ChainingStoreAdministratedProductLoad,
    ): Promise<AdministratedProduct[]> {
        const apiCall = Vue.prototype.$http.get(
            'api/v1/chaining/associatedPatientService/listServicesForSejourGroupByObject/' +
                args.sejourId +
                '?dateMin=' +
                args.dateMin +
                '&dateMax=' +
                args.dateMax +
                '&planification=0' +
                '&onlyActive=1',
            new ChainingAdministratedProductApiAdapter(),
        )
        apiCall.subscribe(response => {
            const result = response.getManyData()
            result.forEach(adminsitratedProduct => {
                adminsitratedProduct.chainings.sort((a, b) => {
                    // we put virtual chaining at the end of the list
                    if (a.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) return 1
                    if (b.id === ChainingDefaultConstants.CHAINING_VIRTUAL_ID) return -1
                    return ChainingHelper.compareTwoStrings(a.name.toLowerCase(), b.name.toLowerCase())
                })
                adminsitratedProduct.chainings.forEach(chaining => {
                    chaining.services.sort((a, b) => {
                        return ChainingHelper.compareTwoStrings(a.name.toLowerCase(), b.name.toLowerCase())
                    })
                })
            })
            data.commit('saveChainingAdministratedProducts', {
                data: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    /**
     * Used to load all chainings from database according to specialty, category and a search name
     * @param data
     * @param args
     */
    loadChainingsManagement(data, args: ChainingStoreLoad): Promise<Chaining[]> {
        const params: ChainingApiParams = {
            populate: 'services,catalogsCategory,chainingCategory',
            search: args.search,
            specialty_id: args.specialty,
            category_id: args.category,
            catalogTypeId: args.context,
            offset: 0,
            limit: 1000,
        }
        if (args.groupId !== ChainingDefaultConstants.CHAINING_NO_INSERTED_ID) {
            params.group_id = args.groupId
        }

        if (null === args.specialty || args.specialty === ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID) {
            delete params.specialty_id
        }

        const apiCall = Vue.prototype.$http.get('api/v1/chaining/chaining', new ChainingApiAdapter(), params)

        apiCall.subscribe(response => {
            const result = response.getManyData()
            result.forEach(chainingData => {
                chainingData.catalogs.forEach(catalog => {
                    if (catalog.chainingCareCatalog && catalog.chainingCareCatalog.libelle) {
                        chainingData.contextsNames.push({
                            type: ChainingCatalogContextEnum.CONTEXT_CARE,
                            name: catalog.chainingCareCatalog.libelle,
                        })
                    } else if (catalog.chainingDrugCatalog && catalog.chainingDrugCatalog.description_french) {
                        chainingData.contextsNames.push({
                            type: ChainingCatalogContextEnum.CONTEXT_DRUG,
                            name: catalog.chainingDrugCatalog.description_french,
                        })
                    } else if (catalog.chainingOperationCatalog && catalog.chainingOperationCatalog.libelle) {
                        chainingData.contextsNames.push({
                            type: ChainingCatalogContextEnum.CONTEXT_OPERATION,
                            name: catalog.chainingOperationCatalog.libelle,
                        })
                    }
                })
            })
            data.commit('saveAllChainingWithCatalogs', {
                list: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadChainings(data, args: ChainingStoreLoad): Promise<Chaining[]> {
        const params: ChainingApiParams = {
            relations: 'refCategory,refSpecialty',
        }

        const paramToSend = {
            search: args.search,
            specialty_id: args.specialty ? args.specialty + ',null' : undefined,
            category_id: args.category,
            group_id: args.groupId + ',null',
        }

        if (args.specialty === ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID) {
            delete paramToSend.specialty_id
        }

        Object.keys(paramToSend).forEach(key => {
            if (paramToSend[key]) {
                params[key] = paramToSend[key]
            }
        })

        const apiCall = Vue.prototype.$http.get('api/v1/chaining/chaining', new ChainingApiAdapter(), params)

        apiCall.subscribe(response => {
            const result = response.getManyData()
            const toStore = result.sort((a, b) =>
                ChainingHelper.compareTwoStrings(a.name.toLowerCase(), b.name.toLowerCase()),
            )
            data.commit('saveAllChainingList', {
                list: toStore,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    replaceService(data, args: ChainingReplaceService): Promise<null> {
        const params = {
            prestationCaisseIds: [args.newService],
            chainingIds: args.chainings,
        }

        const apiCall = Vue.prototype.$http.put(
            'api/v1/chaining/service/replaceService/' + args.serviceToChange,
            new RawApiDataAdapter(),
            params,
        )

        return apiCall.toPromise()
    },
    loadChainingsFromService(data, args: ChainingsFromService): Promise<Chaining[]> {
        const params: ChainingApiParams = {
            search: args.search,
            category_id: args.category_id,
            specialty_id: args.specialty_id ? args.specialty_id + ',null' : undefined,
            chaining_id: args.chaining_id,
            catalogTypeId: args.context,
            populate: args.populate,
            prestationCaisseIsActive: 1,
            prestationCaisseId: args.prestationCaisseId,
            allMatchingServices: true,
        }
        if (args.group_id !== ChainingDefaultConstants.CHAINING_NO_INSERTED_ID) {
            params.group_id = args.group_id
        }

        if (args.specialty_id === ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID) {
            delete params.specialty_id
        }

        const apiCall = Vue.prototype.$http.get('api/v1/chaining/chaining', new ChainingFromServiceApiAdapter(), params)

        apiCall.subscribe(response => {
            const result = response.getManyData()
            result.forEach(chainingData => {
                chainingData.catalogs.forEach(catalog => {
                    if (catalog.chainingCareCatalog && catalog.chainingCareCatalog.libelle) {
                        chainingData.contextsNames.push({
                            type: ChainingCatalogContextEnum.CONTEXT_CARE,
                            name: catalog.chainingCareCatalog.libelle,
                        })
                    } else if (catalog.chainingDrugCatalog && catalog.chainingDrugCatalog.description_french) {
                        chainingData.contextsNames.push({
                            type: ChainingCatalogContextEnum.CONTEXT_DRUG,
                            name: catalog.chainingDrugCatalog.description_french,
                        })
                    } else if (catalog.chainingOperationCatalog && catalog.chainingOperationCatalog.libelle) {
                        chainingData.contextsNames.push({
                            type: ChainingCatalogContextEnum.CONTEXT_OPERATION,
                            name: catalog.chainingOperationCatalog.libelle,
                        })
                    }
                })
            })
            data.commit('saveChainingsFromService', {
                list: result,
                storeKey: args.storeKey,
            })
        })

        return apiCall.toPromise()
    },
    /**
     * Used to load services for a specific chaining from database
     * @param data
     * @param args
     */
    loadChainingWithServices(data, args): Promise<ChainingService[]> {
        const chaining: Chaining = args.chaining
        const params = {
            chaining_id: chaining.id,
            relations: 'refPrestationCaisse',
        }
        const apiCall = Vue.prototype.$http.get('api/v1/chaining/service', new ChainingServiceApiAdapter(), params)

        apiCall.subscribe(response => {
            const result = response.getManyData()
            result.forEach(service => {
                service.chainingId = chaining.id
            })
            chaining.services = result
            data.commit('saveChainingWithServices', {
                chaining: chaining,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },

    updateChainingPatientService(data, params: ChainingPatientServiceUpdateDto): Promise<null> {
        const apiCall = Vue.prototype.$http.put(
            'api/v1/chaining/patientService/' + params.patient_service_id,
            new RawApiDataAdapter(),
            params,
        )
        return apiCall.toPromise()
    },
    deleteChainingPatientService(data, params: ChainingPatientServiceUpdateDto): Promise<null> {
        const apiCall = Vue.prototype.$http.delete(
            'api/v1/chaining/patientService/' + params.patient_service_id,
            new RawApiDataAdapter(),
            params,
        )
        return apiCall.toPromise()
    },
    addChainingPatientService(data, args: ChainingStoreAddPatientService): Promise<ChainingPatientServiceDto> {
        return Vue.prototype.$http
            .post(
                'api/v1/chaining/patientService?populate=user,statusUser',
                new ChainingServiceApiAdapter(),
                args.parameters,
            )
            .toPromise()
    },

    addChainingAssociatedPatientService(data, args: ChainingStoreAddAssociatedPatientService): Promise<Chaining[]> {
        return Vue.prototype.$http
            .post(
                'api/v1/chaining/associatedPatientService/createServicesAndLink?populate=patientService,chaining,patientServiceUser,patientServiceStatusUser',
                new ChainingDefaultApiAdapter(),
                Array.isArray(args.parameters) ? args.parameters : [args.parameters],
            )
            .toPromise()
    },
    updateChainingAssociatedPatientService(data, params: ChainingAssociatedPatientServiceUpdateDto): Promise<null> {
        const apiCall = Vue.prototype.$http.put(
            'api/v1/chaining/associatedPatientService/' + params.associated_patient_service_id,
            new RawApiDataAdapter(),
            params,
        )
        return apiCall.toPromise()
    },
    deleteChainingAssociatedPatientService(data, params: ChainingAssociatedPatientServiceUpdateDto): Promise<null> {
        const apiCall = Vue.prototype.$http.delete(
            'api/v1/chaining/associatedPatientService/' + params.associated_patient_service_id,
            new RawApiDataAdapter(),
            params,
        )
        return apiCall.toPromise()
    },
    createUserDefaultPreference(data, params: ChainingUserPreferenceCreateDto): Promise<number> {
        const apiCall = Vue.prototype.$http.post(
            'api/v1/system/userPreferences',
            new ChainingUserPreferenceApiAdapter(),
            params,
        )
        apiCall.subscribe(response => {
            const insertedId = response.getOneData()
            if (insertedId) {
                data.commit('saveChainingDefaultSpecialtyPreferenceId', {
                    id: insertedId,
                    storeKey: params.storeKey,
                })
            }
        })
        return apiCall.toPromise()
    },
    updateUserDefaultPreference(data, params: ChainingUserPreferenceUpdateDto): Promise<null> {
        const apiCall = Vue.prototype.$http.put(
            'api/v1/system/userPreferences/' + params.pref_id,
            new RawApiDataAdapter(),
            params,
        )
        return apiCall.toPromise()
    },
    /**
     * used to init store basic stuff
     * @param data
     * @param args
     */
    initialization(data, args: ChainingStoreInit): void {
        data.dispatch('setChainingObjectClass', {
            objectClass: args.objectClass,
            storeKey: args.storeKey,
        }).then(() => {
            data.dispatch('setChainingSpecialtyId', {
                id: args.specialtyId,
                storeKey: args.storeKey,
            })
            data.dispatch('setChainingProviderId', {
                id: args.providerId,
                storeKey: args.storeKey,
            })
        })
        data.dispatch('setChainingObjectId', {
            objectId: args.objectId,
            storeKey: args.storeKey,
        })
        data.dispatch('setChainingGroupId', {
            id: args.groupId,
            storeKey: args.storeKey,
        })
        data.dispatch('setChainingDefaultSpecialtyPreferenceId', {
            id: args.userSpecialtyPreferenceId,
            storeKey: args.storeKey,
        })
        data.dispatch('setChainingDefaultProviderPreferenceId', {
            id: args.userProviderPreferenceId,
            storeKey: args.storeKey,
        })
        data.dispatch('setChainingUserId', {
            id: args.userId,
            storeKey: args.storeKey,
        })
        data.dispatch('setChainingDefaultSpecialtyId', {
            id: args.specialtyId,
            storeKey: args.storeKey,
        })
    },
    initializationDrugBase(data, args: ChainingStoreDrugInit): void {
        data.dispatch('setChainingDrugBase', args)
        data.dispatch('setChainingDrugBaseLanguage', args)
    },
    setChainingDrugBase(data, args): void {
        data.commit('saveChainingDrugBase', args)
    },
    setChainingDrugBaseLanguage(data, args): void {
        data.commit('saveChainingDrugBaseLanguage', args)
    },
    setChainingObjectClass(data, args): void {
        data.commit('saveChainingObjectClass', args)
    },
    setChainingObjectId(data, args): void {
        data.commit('saveChainingObjectId', args)
    },
    setChainingGroupId(data, args): void {
        data.commit('saveChainingGroupId', args)
    },
    setChainingSpecialtyId(data, args): void {
        data.commit('saveChainingSpecialtyId', args)
    },
    setChainingUserId(data, args): void {
        data.commit('saveChainingUserId', args)
    },
    setChainingProviderId(data, args): void {
        data.commit('saveChainingProviderId', args)
    },
    setChainingDefaultSpecialtyPreferenceId(data, args): void {
        data.commit('saveChainingDefaultSpecialtyPreferenceId', args)
    },
    setChainingDefaultProviderPreferenceId(data, args): void {
        data.commit('saveChainingDefaultProviderPreferenceId', args)
    },
    setChainingDefaultSpecialtyId(data, args): void {
        data.commit('saveChainingDefaultSpecialtyId', args)
    },
    saveChainingCatalogService({ commit }, args: ChainingManagementCatalogService): Promise<Chaining[]> {
        const apiCall = Vue.prototype.$http.post(
            'api/v1/chaining/chaining/saveWithServicesAndCatalogs',
            new ChainingFromServiceApiAdapter(),
            args,
        )
        return apiCall.toPromise()
    },
    deleteChaining({ commit }, chaining_id: number): Promise<null> {
        const apiCall = Vue.prototype.$http.delete('api/v1/chaining/chaining/' + chaining_id, new RawApiDataAdapter())
        return apiCall.toPromise()
    },
    deleteChainingService({ commit }, service_id: number): Promise<null> {
        return Vue.prototype.$http.delete('api/v1/chaining/service/' + service_id, new RawApiDataAdapter()).toPromise()
    },
    addSpecialty(
        { commit },
        specialty: Partial<ChainingSpecialtyDto>,
    ): Promise<JsonApiResponseAdapter<ChainingSpecialty>> {
        return Vue.$http
            .post('api/v1/chaining/specialty', new ChainingSpecialtyApiAdapter(), specialty)
            .pipe(
                tap(addedSpecialty => {
                    commit('addSpecialty', addedSpecialty.getOneData())
                    commit('sortSpecialties')
                }),
            )
            .toPromise()
    },
    addCategory({ commit }, args): Promise<JsonApiResponseAdapter<ChainingCategory>> {
        return Vue.$http
            .post('api/v1/chaining/category', new ChainingCategoryApiAdapter(), args.category)
            .pipe(
                tap(addedCategory =>
                    commit('addCategory', {
                        category: addedCategory.getOneData(),
                        storeKey: args.storeKey,
                    }),
                ),
            )
            .toPromise()
    },
    deleteSpecialty({ commit }, specialtyId: number): Promise<JsonApiResponseAdapter<ChainingSpecialty>> {
        return Vue.$http
            .delete('api/v1/chaining/specialty/' + specialtyId, new ChainingSpecialtyApiAdapter())
            .pipe(tap(deletedSpecialty => commit('deleteSpecialty', deletedSpecialty.getOneData())))
            .toPromise()
    },
    deleteCategory({ commit }, args): Promise<JsonApiResponseAdapter<ChainingCategory>> {
        return Vue.$http
            .delete('api/v1/chaining/category/' + args.categoryId, new ChainingCategoryApiAdapter())
            .pipe(tap(deletedCategory => commit('deleteCategory', args)))
            .toPromise()
    },
    isObjectReferenced({ commit }, args: ChainingStoreManagementCatalog): Promise<ChainingIsReferenced> {
        return Vue.prototype.$http
            .get('api/v1/chaining/' + args.object_class + '/isReferenced/' + args.object_id, new RawApiDataAdapter())
            .toPromise()
    },
}
