import { Nullable } from '@internal-libraries/kheops-ui-lib'
import { ChainingPatientServiceStatus } from '../types/ChainingPatientService'
import Chaining from '../classes/Chaining'
import ChainingHelper from '../classes/ChainingHelper'
import ChainingUser from '../classes/ChainingUser'

export default class ChainingService {
    public selected = false
    public isActive = true
    public associatedPatientServiceId: Nullable<number> = null
    public specialtyId: Nullable<number> = null //used if the service has no chaining id (virtual chainings)
    public chainingParent: Nullable<Chaining> = null

    public creationHour = '00:00'
    public updateHour = '00:00'

    constructor(
        public id: Nullable<number>,
        public chainingId: Nullable<number> = null,
        public prestationCaisseId: number,
        public name: string,
        public code: string,
        public providerId: Nullable<number> = null,
        public quantity: number = 1,
        public status: ChainingPatientServiceStatus = ChainingPatientServiceStatus.pending,
        public creationDate?: string,
        public creationUser?: ChainingUser,
        public updateDate?: string,
        public updateUser?: ChainingUser,
    ) {
        if (this.creationDate) {
            this.creationHour = ChainingHelper.formatHour(this.creationDate)
            this.creationDate = ChainingHelper.formatDate(this.creationDate)
        }
        if (this.updateDate) {
            this.updateHour = ChainingHelper.formatHour(this.updateDate)
            this.updateDate = ChainingHelper.formatDate(this.updateDate)
        }
    }
}
