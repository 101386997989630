import {get} from 'lodash'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { CategoryPrescriptionDto } from '../types/ChainingCareCatalogDto'
import { CategoryPrescription } from '../classes/CategoryPrescription'

export class CategoryPrescriptionAdapter implements ApiDataAdapter<CategoryPrescription> {
    public createClass(apiData: CategoryPrescriptionDto): CategoryPrescription {
        return new CategoryPrescription(
            get(apiData, 'id', -1),
            get(apiData, 'nom', ''),
            get(apiData, 'chapitre', ''),
        )
    }
}
