import { CategoryPrescription } from "./CategoryPrescription"

export class ChainingCareCatalog {
    constructor(
        public id: number,
        public libelle: string,
        public description: string,
        public categoryPrescriptionId?: number,
        public categoryPrescription?: CategoryPrescription
    ) {}
}
