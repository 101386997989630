import { KhButton, KhComponent, KhExtendedList, KhModal } from '@internal-libraries/kheops-ui-lib'
import { Component, Prop, VModel, Watch } from 'vue-property-decorator'
import ChainingService from '../../classes/ChainingService'
import { Nullable } from '@internal-libraries/kheops-ui-lib'
import { KhExtendedListHelper } from '@internal-libraries/kheops-ui-lib'

/**
 * ChainingServiceFoundSelector
 */
@Component({
    components: {
        KhModal,
        KhExtendedList,
        KhButton,
    },
})
export default class ChainingServiceFoundSelector extends KhComponent {
    @VModel() public dialog
    @Prop({ default: () => [] }) public services!: ChainingService[]
    @Prop() public code?: string

    @Watch('services')
    protected onServiceChange(value): void {
        this.setServiceListItems(value)
    }

    public selectedService: Nullable<ChainingService> = null

    protected get listServices() {
        this.setServiceListItems(this.services)
        return this.services
    }

    //========== DATA
    private key = 0
    private foundServiceListConfig = new KhExtendedListHelper().createListConfig<ChainingService>({
        slots: { content: 'chainingService' },
    })

    //========== FUNCTIONALITY
    public setServiceListItems(services: ChainingService[]): void {
        this.foundServiceListConfig.items = services
    }

    private updateSelectedServices(newSelection: ChainingService[]): void {
        this.selectedService = newSelection[0] || null
    }

    private emitSelection(): void {
        this.$emit('onServiceSelected', this.selectedService)
        this.reset()
    }

    protected get chainingDuplicateServiceConfirmTrad(): string {
        return this.$t('chaining:chaining-service_code_duplicate', {
            code: '<strong>' + this.code + '</strong>',
            interpolation: {
                escapeValue: false,
            },
        })
    }

    private reset(): void {
        this.selectedService = null
        this.foundServiceListConfig.selectedObject = []
        this.foundServiceListConfig.items = []
        this.key++
    }
}
