import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { Nullable } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import ChainingService from '../classes/ChainingService'
import { ChainingServiceDto } from '../types/ChainingServiceDto'
import { ChainingUserApiAdapter } from './ChainingUserApiAdapter'
import { ChainingPrestationCaisseDto } from '../types/ChainingPrestationCaisse/ChainingPrestationCaisseDto'
import { CHAINING_NO_INSERTED_ID } from '../types/ChainingDefault'

export class ChainingServiceApiAdapter implements ApiDataAdapter<ChainingService> {
    createClass(service: ChainingServiceDto): ChainingService {
        const chainingUserAdapter = new ChainingUserApiAdapter()
        const benefitFund: Nullable<ChainingPrestationCaisseDto> = get(service, 'prestationCaisse') || null

        return new ChainingService(
            get(service, 'id'),
            get(service, 'chaining_id'),
            benefitFund?.id || get(service, 'prestation_caisse_id') || CHAINING_NO_INSERTED_ID,
            benefitFund?.libelle || '',
            benefitFund?.code || '',
            get(service, 'provider_id'),
            get(service, 'quantity'),
            get(service, 'status'),
            get(service, 'creation_date'),
            chainingUserAdapter.createClass(get(service, 'user')),
            get(service, 'update_date'),
            chainingUserAdapter.createClass(get(service, 'statusUser')),
        )
    }
}
