import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useChainingStore = defineStore('chaining', () => {
    const inheritSpeciality = ref<boolean>(false)
    const sejourId = ref<number>()
    const groupId = ref<number>()
    const drugBase = ref<string>()
    const drugBaseLanguage = ref<string>()

    return {
        inheritSpeciality,
        sejourId,
        groupId,
        drugBase,
        drugBaseLanguage,
    }
})
